.App {
  text-align: center;
  height:100%;
  line-height: 100%;
  margin: auto;
  position: relative;
}

svg {
 margin: 0;
 position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -55%);
}
